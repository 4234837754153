const prod = {
    url: {
        API: 'https://api.newparentscalendar.com',
    }
}

const dev = {
    url: {
        API: 'http://localhost:3121'
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;