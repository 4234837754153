import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import dayjs, { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Modal, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AccountCircle, Campaign, Clear, DeleteForever, Timeline } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarIcon, DatePicker } from '@mui/x-date-pickers';
import { config } from '../Constants';


const timezoneOffset = new Date().getTimezoneOffset()

function CalRowLabel({ calRow }: { calRow: CalRow }) {
  // console.log(calRow.timestamp)

  var hour: any = Math.floor((calRow.timestamp - timezoneOffset * 60 * 1000) / 1000 % 86400 / 3600)
  if (hour < 10) hour = '0' + hour.toString()

  var minutes: any = Math.floor((calRow.timestamp - timezoneOffset * 60 * 1000) / 1000 % 3600 / 60)
  if (minutes < 10) minutes = '0' + minutes.toString()

  return <div className='cal-row-label'>
    <div className="hour">{hour}</div>
    <div className="sep"></div>
    <div className="minute">{minutes}</div>
  </div>
}

type CalRow = {
  id: string,
  timestamp: number;
  // TODO: add datetime
};

type CalEvent = {
  id: string,
  calRowId: string,
  action: string, // todo: use enum?
  details: any; // TODO: one of the action event details
  // TODO: add datetime
};

type CalEvents = {
  [key: CalRow["id"]]: CalEvent[];
};

function getTopOfRow(calRow: CalRow) {

  let elem = document.getElementById(`cal-row-${calRow.id}`);
  let rect = elem?.getBoundingClientRect()

  if (!rect) return Infinity // TODO: catch, handle

  return rect.top;
}

function getBottomOfRow(calRow: CalRow) {

  let elem = document.getElementById(`cal-row-${calRow.id}`);
  let rect = elem?.getBoundingClientRect()

  if (!rect) return Infinity

  return rect.bottom;
}

const nRow = Math.ceil(window.innerHeight / (3 * 16) + 3) * 2

function doDivsOverlap(div1: any, div2: any) {
  const rect1 = div1.getBoundingClientRect();
  const rect2 = div2.getBoundingClientRect();

  return (
    rect1.left < rect2.right &&
    rect1.right > rect2.left &&
    rect1.top < rect2.bottom &&
    rect1.bottom > rect2.top
  );
}

// const activeDelimiter = window.innerHeight / 2

function getActiveRow(calRows: CalRow[], activeZoneRef: any) {
  // let elemActiveZone = document.getElementById(`active-zone`);
  let elemActiveZone = activeZoneRef.current

  if (!elemActiveZone) return null

  const rectActiveZone = elemActiveZone.getBoundingClientRect()

  if (!rectActiveZone) return null

  for (let i = calRows.length - 1; i > -1; i--) {
    let elem = document.getElementById(`cal-row-${calRows[i].id}`);
    const rect = elem?.getBoundingClientRect()
    if (!rect) {
    } else {
      var overlap = 0

      if (rect.bottom >= rectActiveZone.top && rect.top <= rectActiveZone.top) {
        overlap = rect.bottom - rectActiveZone.top
      } else if (rect.top <= rectActiveZone.bottom && rect.bottom >= rectActiveZone.bottom) {
        overlap = rectActiveZone.bottom - rect.top
      }

      if (overlap > 0 && overlap / (rectActiveZone.bottom - rectActiveZone.top) > 0.5) return elem?.dataset.calRowId
    }
  }
  return null
}

interface Actions {
  feed: any;
  sleep: any;
  change: any;
  plus: any;
}

interface feedEventDetailsType {
  quantity?: number,
  startTs?: number, // default should be timestamp of row
  duration?: number,
  feedType?: string,
  comment?: string
  // TODO: profile
  // TODO: remove question marks
}

function SleepButton({ eventDetails, patchEvent, deleteEvent }: { eventDetails: any, patchEvent: any, deleteEvent: any }) {
  const [open, setOpen] = useState(false)
  const [deleteMode, setDeleteMode] = useState<boolean>(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  useEffect(() => {
    if (deleteMode) setTimeout(() => setDeleteMode(false), 3000)
  }, [deleteMode])

  return <>
    <button
      className='sleep'
      onClick={handleOpen}
    ><img src={actionsAll["sleep"]["icon"]} width={50} height={50} /></button>
    <Modal
      open={open}
      onClose={handleClose}
      className='action-modal'
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >
      <Box className="modal-content">
        <div className='top-right'>
          {deleteMode ? <button className='delete confirm' onClick={deleteEvent}>
            Click to delete <DeleteForever />
          </button> :
            <button className='delete' onClick={() => setDeleteMode(true)}>
              <DeleteForever />
            </button>
          }
        </div>
        {/* <div className='close' onClick={handleClose}><Clear /></div> */}
        <h2>Sleeping details</h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <h3>Start</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ startTs: e.unix() * 1000 })
              }} defaultValue={dayjs(new Date(parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
          <div>
            <h3>End</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ endTs: e.unix() * 1000 })
              }}
                defaultValue={dayjs(new Date(parseInt(eventDetails.endTs) || parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <h3>Comment</h3>
          <TextField multiline={true} rows={3} size='small' fullWidth defaultValue={eventDetails.comment} onChange={(e) => patchEvent({ comment: e.target.value })} />
        </div>
        <button onClick={handleClose} className='npc'>Close</button>
      </Box>
    </Modal>
  </>
}

// TODO: make an abstraction so that we can easily extend it to other actions, even other domains.
function FeedButton({ eventDetails, patchEvent, deleteEvent }: { eventDetails: any, patchEvent: any, deleteEvent: any }) { // TODO: use feedEventDetailsType
  const [open, setOpen] = useState(false)
  const [deleteMode, setDeleteMode] = useState<boolean>(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  useEffect(() => {
    if (deleteMode) setTimeout(() => setDeleteMode(false), 3000)
  }, [deleteMode])

  return <>
    <button
      className="feed"
      onClick={handleOpen}
    ><img src={actionsAll["feed"]["icon"]} width={50} height={50} /></button>
    <Modal
      open={open}
      onClose={handleClose}
      className='action-modal'
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >
      <Box className="modal-content">
        <div className='top-right'>
          {deleteMode ? <button className='delete confirm' onClick={deleteEvent}>
            Click to delete <DeleteForever />
          </button> :
            <button className='delete' onClick={() => setDeleteMode(true)}>
              <DeleteForever />
            </button>
          }
        </div>
        {/* <div className='close' onClick={handleClose}><Clear /></div> */}
        <h2>Feeding details</h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <h3>Start</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ startTs: e.unix() * 1000 })
              }} defaultValue={dayjs(new Date(parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
          <div>
            <h3>End</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ endTs: e.unix() * 1000 })
              }}
                defaultValue={dayjs(new Date(parseInt(eventDetails.endTs) || parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <h3>Type</h3>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={eventDetails.feedType} // TODO: remove default
            onChange={(event, newValue) => patchEvent({ feedType: newValue })}
          //aria-label
          >
            <ToggleButton value='bottle' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Bottle
            </ToggleButton>
            <ToggleButton value='breast-left' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Breast Left
            </ToggleButton>
            <ToggleButton value='breast-right' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Breast Right
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <h3>Quantity</h3>
          <TextField size='small' type='number' sx={{ width: "5rem" }} inputProps={{ style: { textAlign: "right" } }} InputProps={{
            endAdornment: <div style={{ paddingLeft: "6px" }}>ml</div>
          }} />
        </div>
        <div>
          <h3>Comment</h3>
          <TextField multiline={true} rows={3} size='small' fullWidth defaultValue={eventDetails.comment} onChange={(e) => patchEvent({ comment: e.target.value })} />
        </div>
        <button onClick={handleClose} className='npc'>Close</button>
      </Box>
    </Modal>
  </>
}

function ChangeButton({ eventDetails, patchEvent, deleteEvent }: { eventDetails: any, patchEvent: any, deleteEvent: any }) {
  const [open, setOpen] = useState(false)
  const [deleteMode, setDeleteMode] = useState<boolean>(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  useEffect(() => {
    if (deleteMode) setTimeout(() => setDeleteMode(false), 3000)
  }, [deleteMode])

  return <>
    <button
      className='change'
      onClick={handleOpen}
    ><img src={actionsAll["change"]["icon"]} width={50} height={50} /></button>
    <Modal
      open={open}
      onClose={handleClose}
      className='action-modal'
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >
      <Box className="modal-content">
        <div className='top-right'>
          {deleteMode ? <button className='delete confirm' onClick={deleteEvent}>
            Click to delete <DeleteForever />
          </button> :
            <button className='delete' onClick={() => setDeleteMode(true)}>
              <DeleteForever />
            </button>
          }
        </div>
        {/* <div className='close' onClick={handleClose}><Clear /></div> */}
        <h2>Changing details</h2>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <h3>Start</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ startTs: e.unix() * 1000 })
              }} defaultValue={dayjs(new Date(parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
          <div>
            <h3>End</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker onChange={(e) => {
                if (e?.unix()) patchEvent({ endTs: e.unix() * 1000 })
              }}
                defaultValue={dayjs(new Date(parseInt(eventDetails.endTs) || parseInt(eventDetails.startTs)))} sx={{ width: "6.1rem" }} slotProps={{ textField: { size: "small" } }} />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <h3>Type</h3>
          <ToggleButtonGroup
            exclusive
            size="small"
            value={eventDetails.feedType} // TODO: remove default
            onChange={(event, newValue) => patchEvent({ feedType: newValue })}
          //aria-label
          >
            <ToggleButton value='pee' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Pee
            </ToggleButton>
            <ToggleButton value='poop' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Poop
            </ToggleButton>
            <ToggleButton value='both' sx={{ textTransform: "none", fontSize: "inherit" }}>
              Both
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <h3>Comment</h3>
          <TextField multiline={true} rows={3} size='small' fullWidth defaultValue={eventDetails.comment} onChange={(e) => patchEvent({ comment: e.target.value })} />
        </div>
        <button onClick={handleClose} className='npc'>Close</button>
      </Box>
    </Modal>
  </>
}

const actionsAll: Actions = {
  feed: {
    label: "feed",
    icon: "/static/icons8-baby-bottle-ios-16/icons8-baby-bottle-50.png",
  },
  sleep: {
    label: "sleep",
    icon: "/static/icons8-napping-ios-16/icons8-napping-50.png",
  },
  change: {
    label: "change",
    icon: "/static/icons8-nappy-ios-16/icons8-nappy-50.png",
  },
  plus: {
    label: "plus",
    icon: "/static/icons8-plus-ios-16/icons8-plus-50.png"
  }
}

var dateNow = Math.round(Date.now() / (1000 * 60 * 15)) * (1000 * 60 * 15) // TODO: refresh when user opens back the page?

function CalendarView() {
  const [calRows, setCalRows] = useState<CalRow[]>([])
  const [firstVisibleRow, setFirstVisibleRow] = useState<CalRow | null>(null)
  const [lastVisibleRow, setLastVisibleRow] = useState<CalRow | null>(null)
  const [activeRow, setActiveRow] = useState<CalRow["id"] | null>(null)
  const [calEvents, setCalEvents] = useState<CalEvents | null>(null)
  const [startTs, setStartTs] = useState<number>(
    dateNow - 1000 * 60 * 15 * Math.floor(nRow / 2)
  )
  const [endTs, setEndTs] = useState<number>(
    dateNow + 1000 * 60 * 15 * Math.ceil(nRow / 2)
  )
  const [firstLoad, setFirstLoad] = useState(true)
  const [addingRows, setAddingRows] = useState(false)
  const calendarViewRef = useRef<any>(null);
  const activeZoneRef = useRef<any>(null);
  const [value, setValue] = React.useState<Dayjs | null>(null); // dayjs(new Date())



  // TODO: define row increment. 

  useEffect(() => {
    const events = window.localStorage.getItem('calendar-events')

    if (events === "null") setCalEvents({})
    else setCalEvents(JSON.parse(events || '{}'))
  }, [])

  const [scrollToRow, setScrollToRow] = useState<CalRow["id"] | null>(null)

  function findAndSetFirstVisibleRow() {     // TODO: just return it? -->  that way we can take the function out
    for (let i = 0; i < calRows.length; i++) {
      let elem = document.getElementById(`cal-row-${calRows[i].id}`);
      let rect = elem?.getBoundingClientRect()

      if (!rect) continue

      if (rect.bottom > 0) {
        setFirstVisibleRow(calRows[i])
        break
      }
    }
  }

  function findAndSetLastVisibleRow() {     // TODO: just return it?
    for (let i = calRows.length - 1; i > -1; i--) {
      let elem = document.getElementById(`cal-row-${calRows[i].id}`);
      let rect = elem?.getBoundingClientRect()

      if (!rect) continue

      if (rect.top < window.innerHeight) {
        setLastVisibleRow(calRows[i])
        break
      }
    }
  }

  useEffect(() => {
    if (calEvents !== null && !firstLoad) {
      window.localStorage.setItem('calendar-events', JSON.stringify(calEvents))
    }
  }, [calEvents])

  // useEffect(() => {
  // window.history.scrollRestoration = 'manual'
  // }, [])

  useEffect(() => {
    var newCalRows = []

    var rowTimestamp = startTs

    while (rowTimestamp < endTs) {
      const rowId = rowTimestamp.toString()
      newCalRows.push({
        id: rowId, // We need a deterministic id
        timestamp: rowTimestamp
      })

      if (firstLoad && rowTimestamp === dateNow) {
        setScrollToRow(rowId)
        setFirstLoad(false)
      }

      rowTimestamp += 1000 * 60 * 15
    }

    setCalRows(newCalRows)
  }, [startTs, endTs]) // []

  // function addRows(n) {
  //   const rowTs = calRows.slice(-1)[0].timestamp + 15 * 60 * 1000

  //   setCalRows(
  //     [...calRows, {
  //       id: rowTs.toString(),
  //       timestamp: rowTs
  //     }]
  //   )
  // }

  useEffect(() => {
    if (scrollToRow) {

      const ele = document.getElementById(`cal-row-${scrollToRow}`)

      if (ele) ele.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    }
  }, [scrollToRow])

  useEffect(() => {
    // TODO: check that it's not looping too much
    findAndSetFirstVisibleRow()
    findAndSetLastVisibleRow()
    calendarViewRef.current.style.scrollSnapType = 'y mandatory'
    setAddingRows(false)

  }, [calRows])

  useEffect(() => {
    // console.log("first visible row", firstVisibleRow?.timestamp)

    if (firstVisibleRow?.timestamp && firstVisibleRow.timestamp - startTs < 15 * 60 * 1000 * 5) { // Distance to limit given by time. Could be given by the div boudaries instead. TODO: choose best option.      
      calendarViewRef.current.style.scrollSnapType = 'none'

      if (!addingRows) { // TODO: make it cleaner with a function dedicated to adding rows before
        setAddingRows(true)
        setTimeout(() => {
          setStartTs(startTs - 24 * 15 * 60 * 1000) // TODO: use jump between rows
        }, 500)
      }
    }
    // console.log("endTs", endTs, " lastVisibleRow.timestamp", lastVisibleRow?.timestamp,)

    if (lastVisibleRow?.timestamp && (endTs - lastVisibleRow.timestamp < 15 * 60 * 1000 * 5)) { // Distance to limit given by time. Could be given by the div boudaries instead. TODO: choose best option.
      calendarViewRef.current.style.scrollSnapType = 'none'
      if (!addingRows) { // TODO: same as for before
        setAddingRows(true)
        setTimeout(() => {
          setEndTs(endTs + 24 * 15 * 60 * 1000) // TODO: use jump between rows
        }, 500)
      }
    }
  }, [firstVisibleRow, lastVisibleRow])

  function handleScroll(event: any) {

    // --> set interval to detect if scroll ended 

    const row = getActiveRow(calRows, activeZoneRef)

    if (row !== null && typeof (row) !== 'undefined') setActiveRow(row)


    // TODO: do we need to know which one is first and last visible row? We could use the box position
    if (firstVisibleRow && (getTopOfRow(firstVisibleRow) > 0 || getBottomOfRow(firstVisibleRow) < 0)) {
      findAndSetFirstVisibleRow()
    }

    if (lastVisibleRow && (getBottomOfRow(lastVisibleRow) < window.innerHeight || getTopOfRow(lastVisibleRow) > window.innerHeight)) {
      findAndSetLastVisibleRow()
    }
    // calendarViewRef.current.style.scrollSnapType = 'y proximity'
  };

  function newEvent(action: string) {
    if (action && activeRow) {
      const newEvent: CalEvent = {
        id: Math.random().toString(),
        calRowId: activeRow,
        action,
        details: {
          startTs: activeRow // TODO: activeRow.timestamp
        } // TODO: fill depending on action
      }

      var newCalEvents = { ...calEvents }

      if (calEvents && activeRow in calEvents && calEvents[activeRow].length > 0) {
        newCalEvents[activeRow] = [...calEvents[activeRow], newEvent]
      } else {
        newCalEvents[activeRow] = [newEvent]
      }

      setCalEvents(newCalEvents)
    }
  }

  function patchEvent(calRow: CalRow, eventIndex: number, properties: any) {
    var newCalEvents = JSON.parse(JSON.stringify(calEvents))

    if (!newCalEvents) return

    // TODO: optimize
    newCalEvents[calRow.id][eventIndex].details = {
      ...newCalEvents[calRow.id][eventIndex].details,
      ...properties
    }

    setCalEvents(newCalEvents)
  }

  function deleteEvent(calRow: CalRow, eventIndex: number) {
    var newCalEvents = JSON.parse(JSON.stringify(calEvents))

    if (!newCalEvents) return

    // TODO: optimize
    newCalEvents[calRow.id].splice(eventIndex, 1)

    setCalEvents(newCalEvents)
  }

  return <>
    <div id='day-wrapper'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{
            textField: {
              size: 'small',
              style: {
                width: "10rem"
              },
              InputProps: {
                endAdornment: <CalendarIcon />
              },
              sx: {
                fieldset: {
                  border: 0
                },
              }
            },
          }}
          // size
          // label="Basic example"
          value={activeRow ? dayjs(new Date((parseInt(activeRow)))) : null}
          onChange={(newValue: any) => {
            setValue(newValue);
          }}
        // renderInput={(params: any) => <TextField {...(params || {})} />}
        />
      </LocalizationProvider>
    </div>
    <div id='calendar-view-wrapper'>
      <div id='active-zone' ref={activeZoneRef}>
        <div>
          {['feed', 'sleep', 'change'].map((action: string) => <button
            className={'action' + ' ' + action}
            key={action}
            onClick={() => {
              newEvent(action)
            }}
          ><img src={actionsAll[action as keyof Actions]["icon"]} width={50} height={50} /></button>)}
          {/* <button
        ><img src={actionsAll["plus" as keyof Actions]["icon"]} width={50} height={50} /></button> */}
        </div>
      </div>
      <div id='calendar-view' onScroll={handleScroll} ref={calendarViewRef}> {/** TODO: check once it s not 100vh */}
        <div style={{ position: "relative", minHeight: "100%", width: "100%" }}>
          {calRows.map(calRow => {
            return <div key={`cal-row-${calRow.id}`} data-cal-row-id={calRow.id} className={'cal-row' + (calRow.id === activeRow ? " active" : "")} id={`cal-row-${calRow.id}`}
            >
              <div className='label'><CalRowLabel calRow={calRow} /></div>
              {(calEvents && calRow.id in calEvents) && <div className='events'>
                {calEvents[calRow.id].map((event, eventIndex) => {
                  // TODO: define key
                  if (event.action === "feed") return <FeedButton key={`${calRow.id}-${calEvents[calRow.id].length}-${eventIndex}-${event.action}`} eventDetails={event.details} patchEvent={(properties: any) => patchEvent(calRow, eventIndex, properties)} deleteEvent={() => deleteEvent(calRow, eventIndex)} />
                  if (event.action === "sleep") return <SleepButton key={`${calRow.id}-${calEvents[calRow.id].length}-${eventIndex}-${event.action}`} eventDetails={event.details} patchEvent={(properties: any) => patchEvent(calRow, eventIndex, properties)} deleteEvent={() => deleteEvent(calRow, eventIndex)} />
                  if (event.action === "change") return <ChangeButton key={`${calRow.id}-${calEvents[calRow.id].length}-${eventIndex}-${event.action}`} eventDetails={event.details} patchEvent={(properties: any) => patchEvent(calRow, eventIndex, properties)} deleteEvent={() => deleteEvent(calRow, eventIndex)} />
                })}
              </div>}
            </div>
          })}
        </div>
        {calEvents === null && <Box sx={{ zIndex: "999", display: 'flex', position: "absolute", inset: "0", justifyContent: "center", alignItems: "center", background: "rgba(0, 0, 0, 0.25)" }}>
          <CircularProgress sx={{ color: "black" }} />
        </Box>}
      </div>
    </div>
  </>
}

function Footer({ selectTab, selectedTab }: { selectTab: any, selectedTab: String }) {
  return <div className='tab-selectors'>
    <div className={'tab-selector calendar' + (selectedTab === 'calendar' ? " selected" : "")} onClick={() => selectTab("calendar")}>
      <div><CalendarIcon /></div>
      <label>Calendar</label>
    </div>
    <div className={"tab-selector timeline" + (selectedTab === 'timeline' ? " selected" : "")} onClick={() => selectTab("timeline")}>
      <div>
        <Timeline />
      </div>
      <label>Timeline</label>
    </div>
    <div className={"tab-selector profile" + (selectedTab === 'profile' ? " selected" : "")} onClick={() => selectTab("profile")}>
      <div><AccountCircle /></div>
      <label>Account</label>
    </div>
    <div className={"tab-selector feedback" + (selectedTab === 'feedback' ? " selected" : "")} onClick={() => selectTab("feedback")}>
      <div><Campaign /></div>
      <label>Feedback</label>
    </div>
  </div>

}

function ProfileView() {
  return <div className="profile-view page">
    <h1>Your Profile</h1>
    <div> This section is under construction.</div>
  </div>
}

function TimelineView() {
  return <div className="timeline-view page">
    <h1>Timeline & Statistics</h1>
    <div> This section is under construction.</div>
  </div>
}

function StarRating({ rating, setRating }: { rating: number, setRating: any }) {
  return <div className='star-rating'>
    <div><h2>How happy are you with this application?</h2></div>
    <div className="stars">
      {[...Array(5).keys()].map(v => <div className={
        "star" + (rating >= v ? " active" : " inactive")
      }
        onClick={() => setRating(v)}
      >
        ⭐
      </div>)}
    </div>
  </div>
}

function LoadingButton({ loading, label, onClick }: { loading: boolean, label: String, onClick: any }) {
  return <button className='npc' onClick={onClick}>
    {label}
    {loading && <div className='loader'>
      <CircularProgress style={{ width: 20, height: 20 }} />
    </div>}
  </button>
}

function FeedbackView() {
  const [rating, setRating] = useState<number>(-1)
  const [comment, setComment] = useState('')
  const [sending, setSending] = useState<boolean>(false)
  const [sendingSuccess, setSendingSuccess] = useState<boolean | null>(null)

  useEffect(() => {
    setSendingSuccess(null)
  }, [rating, comment])

  return <div className="feedback-view page">
    <h1>Feedback & Contact</h1>
    <br />
    <div>
      <StarRating rating={rating} setRating={setRating} />
      <br />
      <div className="open-comment">
        <h2>Any request or word you would like to share with the team?</h2>
        <TextField multiline rows={5} value={comment} onChange={(e) => setComment(e.target.value)} fullWidth />
      </div>
      <div>
        <LoadingButton loading={sending} label="Send Feedback" onClick={() => {
          if (rating < 0 && !comment) return

          if (sending) return

          setSending(true)

          fetch(config.url.API + '/feedback', {
            method: "PUT",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ rating, comment })
          }).then(resp => {
            if (resp.status === 200) {
              setSendingSuccess(true)
              setSending(false)
            } else {
              setSendingSuccess(false)
              setSending(false)
            }
          })
        }} />
        {sendingSuccess === true && <p className='status success'>Success. Thank you for your feedback!</p>}
        {sendingSuccess === false && <p className='status error'>Oops... Something went wrong and we could not receive your feedback.</p>}
      </div>
    </div>
  </div>
}

function App() {
  const [tab, setTab] = useState<String>('calendar') // TODO: define enum

  return (
    <div id="calendar-app">
      {tab === "calendar" && <CalendarView />}
      {tab === "profile" && <ProfileView />}
      {tab === "timeline" && <TimelineView />}
      {tab === "feedback" && <FeedbackView />}
      <Footer selectTab={setTab} selectedTab={tab} />
    </div>
  );
}

export default App;
